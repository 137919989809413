/*
JOSEPH P. PASAOA
BlogPage Component | Portfolio Site
*/


/* IMPORTS */
import React from 'react';


/* MAIN */
const BlogPage = () => {

  return (
    <div className="page page--blog flex-column">
      BlogPage
    </div>
  );
}


export default BlogPage;
