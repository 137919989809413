/*
JOSEPH P. PASAOA
ImportsRef Helper | Portfolio Site
*/


/* IMPORTS */
// const joeysResume = process.env.PUBLIC_URL + '/docs/Joseph_P_Pasaoa_Developer_resume.pdf';
const joeysResume = process.env.PUBLIC_URL + '/docs/Full-Stack_Developer_Resume_(Joseph_P_Pasaoa).pdf';


/* MAIN */
module.exports = {
  joeysResume
}
