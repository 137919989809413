/*
JOSEPH P. PASAOA
SiteInfoPage Component | Portfolio Site
*/


/* IMPORTS */
import React from 'react';


/* MAIN */
const SiteInfoPage = () => {

  return (
    <div className="page page--siteinfo flex-column">
      SiteInfoPage
    </div>
  );
}


export default SiteInfoPage;
