/*
JOSEPH P. PASAOA
PhotographyPage Component | Portfolio Site
*/


/* IMPORTS */
import React from 'react';


/* MAIN */
const PhotographyPage = () => {

  return (
    <div className="page page--photography flex-column">
      PhotographyPage
    </div>
  );
}


export default PhotographyPage;
